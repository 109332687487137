<template>
  <div class="vehicle-assess">
    <div class="page-content">
      <Form
        :ref="`form-vehicle`"
        :defaultForm.sync="form"
        :fields="fields"
        :defaultText.sync="fieldTexts"
      />
      <template v-if="evalStatus">
        <div v-for="(item, i) in enums" :key="i" class="list-item">
          <div class="item-tit">
            <span class="name">{{ item.desc }}</span>
            <span class="example" @click="previewDemo(item.value)">
              <van-icon name="eye-o" />
              示例
            </span>
          </div>
          <Upload
            v-model="item.imgs"
            previewVali="mobile"
            :maxCount="1"
            :isDefaultAccept="false"
            accept=".jpg"
            @upload="uploadFunction($event, item, i)"
            @del="deleteFile($event, item, i)"
          />
        </div>
      </template>
    </div>

    <div class="page-btn btns">
      <van-button size="small" @click="$router.go(-1)">返回</van-button>
      <van-button type="info" size="small" @click="assessFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ImagePreview } from "vant";
import {
  assessVehiclev2,
  assessEnums,
  assessChannel,
  orderDetailInfo
} from "@/api/apply";
export default {
  components: {
    Form: () => import("@/components/Form/Index"),
    Upload: () => import("@/components/Upload.vue")
  },
  computed: {
    fields() {
      let arr = [
        {
          type: "text",
          label: "车型",
          required: true,
          key: "modelName"
        },
        {
          type: "text",
          label: "车辆颜色",
          required: true,
          key: "carColor"
        },
        {
          type: "number",
          label: "行驶里程(公里)",
          required: true,
          labelWidth: "7.2em",
          key: "workDistance",
          maxlength: 7,
          validator: (v) => {
            const reg = /^(?:0|(?:-?[1-9]\d*))$/;
            if (!v) throw `请输入行驶里程`;
            else if (v && +v < 0) throw `行驶里程大于等于零的数字`;
            else if (v && !reg.test(v)) throw `行驶里程请输入整数`;
            else return true;
          }
        }
      ];
      return arr;
    }
  },
  data() {
    return {
      bizNo: "",
      fieldTexts: {},
      form: {},
      enums: [],
      evalStatus: false,
      // imgs: [],
      params: []
    };
  },
  watch: {
    evalStatus(v) {
      if (!v) {
        this.enums = [];
      }
    }
  },
  mounted() {
    const data = this.getUserOrderInfo();
    this.bizNo = data.bizNo || "";
    this.getAssessChannel();
    this.getApplyInfo();
    this.getEnums();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    uploadFunction(param, item, i) {
      const { url } = param;
      const { key } = item;
      this.$set(this.params, i, {
        partCode: key,
        imageUrl: url
      });
    },
    deleteFile(index, item, i) {
      // item.imgs[i].splice(index, 1);
      this.params.splice(i, 1);
    },

    async getAssessChannel() {
      try {
        const { data } = await assessChannel(this.bizNo);
        this.evalStatus = data.evalStatus == "Y";
        console.log(this.evalStatus);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getEnums() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await assessEnums();
        this.enums = (data || []).map((e) => {
          return {
            ...e,
            imgs: []
          };
        });
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    // 申请信息
    async getApplyInfo() {
      try {
        this.loading = true;
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await orderDetailInfo(this.bizNo);
        const { modelName } = data;
        this.$set(this.form, "modelName", modelName);
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    async assessFn() {
      try {
        const infodom = this.$refs["form-vehicle"];
        await infodom.validate();
        let params = {
          bizNo: this.bizNo,
          ...this.form
        };
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        if (this.evalStatus) {
          params.images = this.params;
        }
        // const params = {
        //   images: this.params
        // };
        const { data } = await assessVehiclev2(this.bizNo, params);
        const carValue = data?.carValue;
        this.$toast.success("提交成功");
        this.$router.push(`/vehicle-info?carValue=${carValue}`);
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vehicle-assess {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.page-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 32px;
}
.list-item {
  .item-tit {
    display: flex;
    font-size: 14px;
    line-height: 88px;
    .name {
      color: #333;
    }
    .example {
      margin-left: 32px;
      font-size: 28px;
      color: #faad14;
    }
  }
}
.page-btn {
  height: 112px;
  min-height: 112px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #eee;
  box-shadow: 0 -10px 10px #f6f6f6;
  z-index: 10;
  .van-button {
    height: 100%;
    width: 45%;
  }
}
</style>
